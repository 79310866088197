import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { IsLoggedInGuard } from './_guards/isLoggedIn.guard';
import { OnboardGuard } from './_guards/onboard.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./loader/loader.module').then((m) => m.LoaderPageModule),
	},
	{
		path: '',
		redirectTo: 'tabs',
		pathMatch: 'full',
	},
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
	},
	{
		path: 'shows',
		loadChildren: () => import('./events/events.module').then((m) => m.EventsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'gigs',
		loadChildren: () => import('./gigs/gigs.module').then((m) => m.GigsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'show/:id',
		loadChildren: () => import('./event/event.module').then((m) => m.EventPageModule),
		//commented based on client requirement
		// canActivate: [AuthGuard],
	},
	{
		path: 'gig/:id',
		loadChildren: () => import('./gig/gig.module').then((m) => m.GigPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'artists',
		loadChildren: () => import('./artists/artists.module').then((m) => m.ArtistsPageModule),
		canActivate: [AuthGuard],
	},
	// {
	//   path: 'artist/:id',
	//   loadChildren: () => import('./artist/artist.module').then((m) => m.ArtistPageModule),
	//   canActivate: [AuthGuard],
	// },
	{
		path: 'chat/:profileId',
		loadChildren: () => import('./chat/chat.module').then((m) => m.ChatPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'profile',
		loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'upgrade',
		loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'applications',
		loadChildren: () => import('./applications/applications.module').then((m) => m.ApplicationsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'bookings',
		loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'chats',
		loadChildren: () => import('./chats/chats.module').then((m) => m.ChatsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'notifications',
		loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationPageModule),
		canActivate: [IsLoggedInGuard],
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
		canActivate: [IsLoggedInGuard],
	},
	{
		path: 'login-verification',
		loadChildren: () => import('./login-verification/login-verification.module').then((m) => m.LoginVerificationPageModule),
		canActivate: [IsLoggedInGuard],
	},
	{
		path: 'onboard',
		loadChildren: () => import('./onboard/onboard.module').then((m) => m.OnboardPageModule),
		canActivate: [AuthGuard],
	},

	{
		path: 'onboard-name',
		loadChildren: () => import('./onboard-name/onboard-name.module').then((m) => m.OnboardNamePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-birthday',
		loadChildren: () => import('./onboard-birthday/onboard-birthday.module').then((m) => m.OnboardBirthdayPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-gender',
		loadChildren: () => import('./onboard-gender/onboard-gender.module').then((m) => m.OnboardGenderPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-pictures',
		loadChildren: () => import('./onboard-pictures/onboard-pictures.module').then((m) => m.OnboardPicturesPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-company',
		loadChildren: () => import('./onboard-company/onboard-company.module').then((m) => m.OnboardCompanyPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-bio',
		loadChildren: () => import('./onboard-bio/onboard-bio.module').then((m) => m.OnboardBioPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-username-type',
		loadChildren: () => import('./onboard-username-type/onboard-username-type.module').then((m) => m.OnboardUsernameTypePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-genres',
		loadChildren: () => import('./onboard-genres/onboard-genres.module').then((m) => m.OnboardGenresPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-repritoir',
		loadChildren: () => import('./onboard-repritoir/onboard-repritoir.module').then((m) => m.OnboardRepritoirPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-skill-level',
		loadChildren: () => import('./onboard-skill-level/onboard-skill-level.module').then((m) => m.OnboardSkillLevelPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-music',
		loadChildren: () => import('./onboard-music/onboard-music.module').then((m) => m.OnboardMusicPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-music-preview',
		loadChildren: () => import('./onboard-music-preview/onboard-music-preview.module').then((m) => m.OnboardMusicPreviewPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-merch',
		loadChildren: () => import('./onboard-merch/onboard-merch.module').then((m) => m.OnboardMerchPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-social-accounts',
		loadChildren: () => import('./onboard-social-accounts/onboard-social-accounts.module').then((m) => m.OnboardSocialAccountsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'onboard-location',
		loadChildren: () => import('./onboard-location/onboard-location.module').then((m) => m.OnboardLocationPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'ticket/:id',
		loadChildren: () => import('./ticket/ticket.module').then((m) => m.TicketPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'tickets',
		loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'terms',
		loadChildren: () => import('./terms/terms.module').then((m) => m.TermsPageModule),
	},
	{
		path: 'rules',
		loadChildren: () => import('./rules/rules.module').then((m) => m.RulesPageModule),
	},
	{
		path: 'privacy',
		loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyPageModule),
	},
	{
		path: 'eula',
		loadChildren: () => import('./eula/eula.module').then((m) => m.EulaPageModule),
	},
	{
		path: 'explore',
		loadChildren: () => import('./explore/explore.module').then((m) => m.ExplorePageModule),
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'artist/detail/:username',
		loadChildren: () => import('./artist-profile/artist-profile.module').then((m) => m.ArtistProfilePageModule),
	},
	{
		path: 'industry/:username',
		loadChildren: () => import('./artist-profile/artist-profile.module').then((m) => m.ArtistProfilePageModule),
	},
	{
		path: 'artist/:id',
		loadChildren: () => import('./artist-profile/artist-profile.module').then((m) => m.ArtistProfilePageModule),
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'create-gig',
		loadChildren: () => import('./create-gig/create-gig.module').then((m) => m.CreateGigPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'edit-gig',
		loadChildren: () => import('./create-gig/create-gig.module').then((m) => m.CreateGigPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'followers',
		loadChildren: () => import('./followers/followers.module').then((m) => m.FollowersPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'create-post',
		loadChildren: () => import('./create-post/create-post.module').then((m) => m.CreatePostPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'comments/:postId',
		loadChildren: () => import('./post-comments/post-comments.module').then((m) => m.PostCommentsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'applicants/:id',
		loadChildren: () => import('./industry-applicants/industry-applicants.module').then((m) => m.IndustryApplicantsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'create-show',
		loadChildren: () => import('./create-event/create-show.module').then((m) => m.CreateEventPageModule),
		canActivate: [AuthGuard],
	},
	// {
	// 	path: 'subscriptions',
	// 	loadChildren: () => import('./subscriptions/subscriptions.module').then((m) => m.SubscriptionsPageModule),
	// 	canActivate: [AuthGuard],
	// },
	{
		path: 'subs-current-plan',
		loadChildren: () => import('./subs-current-plan/subs-current-plan.module').then((m) => m.SubsCurrentPlanPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'book-now/:userId',
		loadChildren: () => import('./book-now/book-now.module').then((m) => m.BookNowPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'booking-offer',
		loadChildren: () => import('./booking-offer/booking-offer.module').then((m) => m.BookingOfferPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'faqs',
		loadChildren: () => import('./faqs/faqs.module').then((m) => m.FaqsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'support',
		loadChildren: () => import('./support/support.module').then((m) => m.SupportPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'invite-friends',
		loadChildren: () => import('./invite-friends/invite-friends.module').then((m) => m.InviteFriendsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'edit-account',
		loadChildren: () => import('./edit-account/edit-account.module').then((m) => m.EditAccountPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'post/:postId',
		loadChildren: () => import('./post/post.module').then((m) => m.PostPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'favorites',
		loadChildren: () => import('./favourites/favourites.module').then((m) => m.FavouritesPageModule),
	},
	{
		path: 'leaderboard',
		loadChildren: () => import('./leaderboard/leaderboard.module').then((m) => m.LeaderboardPageModule),
	},
	{
		path: 'contest-rules',
		loadChildren: () => import('./contest-rules/contest-rules.module').then((m) => m.ContestRulesPageModule),
	},
	{
		path: 'contest-winner',
		loadChildren: () => import('./contest-winner/contest-winner.module').then((m) => m.ContestWinnerPageModule),
	},
	{
		path: 'ticket-analytics/:showId',
		loadChildren: () => import('./ticket-analytics/ticket-analytics.module').then((m) => m.TicketAnalyticsPageModule),
	},
	{
		path: 'all-attendees/:showId/:orderRef',
		loadChildren: () => import('./all-attendees/all-attendees.module').then((m) => m.AllAttendeesPageModule),
	},
	{
		path: 'show-tickets-detail/:showId',
		loadChildren: () => import('./show-tickets-detail/show-tickets-detail.module').then((m) => m.showTicketsDetailPageModule),
	},
	{
		path: 'order-details',
		loadChildren: () => import('./order-details/order-details.module').then((m) => m.orderDetailsPageModule),
	},
	{
		path: 'gigmor-ticket-terms-faqs',
		loadChildren: () => import('./gigmor-ticket-terms-faqs/gigmor-ticket-terms-faqs.module').then((m) => m.GigmorTicketTermsFaqsPageModule),
	},
	{
		path: 'upcoming-shows',
		loadChildren: () => import('./upcoming-shows/upcoming-shows.module').then((m) => m.UpcomingShowsPageModule),
	},
	{
		path: 'sales/:showId',
		loadChildren: () => import('./sales/sales.module').then((m) => m.SalesPageModule),
	},
	{
		path: 'edit-ticket/:showId/:ticketId',
		loadChildren: () => import('./edit-ticket/edit-ticket.module').then((m) => m.EditTicketPageModule),
	},
	{
		path: 'past-sales',
		loadChildren: () => import('./past-sales/past-sales.module').then((m) => m.PastSalesPageModule),
	},
	{
		path: 'orders/:showId',
		loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersPageModule),
	},
	{
		path: 'order/:referenceId',
		loadChildren: () => import('./order/order.module').then((m) => m.OrderPageModule),
	},
	{
		path: 'edit-order/:referenceId',
		loadChildren: () => import('./edit-order/edit-order.module').then((m) => m.EditOrderPageModule),
	},
	{
		path: 'show-tickets/:showId',
		loadChildren: () => import('./show-tickets/show-tickets.module').then((m) => m.ShowTicketsPageModule),
	},
	{
		path: 'comp-ticket/:showId',
		loadChildren: () => import('./comp-ticket/comp-ticket.module').then((m) => m.CompTicketPageModule),
	},
	{
		path: 'show-ticket-detail',
		loadChildren: () => import('./show-tickets-detail/show-tickets-detail.module').then((m) => m.showTicketsDetailPageModule),
	},
	{
		path: 'gigmor-ticket/:reference/:showId',
		loadChildren: () => import('./gigmor-ticket/gigmor-ticket.module').then((m) => m.GigmorTicketPageModule),
	},
	{
		path: 'order-confirmation/:reference/:showId',
		loadChildren: () => import('./order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationPageModule),
	},
	{
		path: 'checkin-orders/:showId',
		loadChildren: () => import('./checkin-orders/checkin-orders.module').then((m) => m.CheckinOrdersPageModule),
	},
	{
		path: 'wallet',
		loadChildren: () => import('./wallet/wallet.module').then((m) => m.WalletPageModule),
	},
	{
		path: 'wallet/payment',
		loadChildren: () => import('./wallet/payment/payment.module').then((m) => m.PaymetPageModule),
	},
	{
		path: 'tos',
		loadChildren: () => import('./tos/tos.module').then((m) => m.TosPageModule),
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
