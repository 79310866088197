import { Injectable } from '@angular/core';
// import { GlassfyPermission } from 'capacitor-plugin-glassfy';
import { BehaviorSubject } from 'rxjs';
import { UserSubscription } from '../_types/subscription.types';

@Injectable()
export class AppService {
  // public static permissions: GlassfyPermission[] = [];

  public static restore_required = false;
  public static glassfy_subscriberId = '';
  public static subscriptions: UserSubscription[] = [];
  public static get activeSubscription(): UserSubscription {
    return this.subscriptions.find((x) => x.isActive);
  }
  public static user: any;
  public static glassfy_initialized = false;
  public static PLATFORM = '';
  public static store = 'stripe';

  public static get isPro(): boolean {
    if (this.subscriptions.length < 1) return false;

    const lastExpirySub = this.subscriptions.sort(
      (a, b) => b.expiresOn.getTime() - a.expiresOn.getTime()
    )[0];

    if (!lastExpirySub) return false;

    return !this.isExpired(lastExpirySub.expiresOn);
  }

  public static get canSendMessage(): boolean {
    if (this.user && this.user.role.toLowerCase() === 'artist') {
      return this.isPro;
    }

    return true;
  }

  static isExpired(date: Date) {
    const t = new Date(date);
    const now = new Date();

    return now.getTime() > t.getTime();
  }
}
