import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '../_types/server-response';
import { UserSubscription } from '../_types/subscription.types';
import { AppService } from './app.service';
import * as moment from 'moment';
import { StripePlan } from '../_types/stripe';

@Injectable({
  providedIn: 'root',
})
export class SubscriptiontService {
  httpOptions = {};

  constructor(private http: HttpClient) {}

  get(): Observable<UserSubscription> {
    return this.http.get<UserSubscription>(
      environment.API_URL + `/subscription/get`,
      this.httpOptions
    );
  }

  getAllByUser(): Observable<UserSubscription[]> {
    return this.http.get<UserSubscription[]>(
      environment.API_URL + `/subscription/get-all`,
      this.httpOptions
    );
  }

  getBySubscriber(): Observable<UserSubscription[]> {
    return this.http.get<UserSubscription[]>(
      environment.API_URL + `/subscription/` + AppService.glassfy_subscriberId,
      this.httpOptions
    );
  }

  getStripePlans(): Observable<StripePlan[]> {
    return this.http.get<StripePlan[]>(
      environment.API_URL + `/subscription/get-stripe-plans`,
      this.httpOptions
    );
  }

  getStripeCards(): Observable<any> {
    return this.http.get(
      environment.API_URL + `/subscription/get-stripe-cards`,
      this.httpOptions
    );
  }

  addCard(stripeToken: string): Observable<any> {
    const fd = new FormData();
    fd.append('stripeToken', stripeToken.trim());

    return this.http.post(environment.API_URL + `/subscription/add-card`, fd);
  }

  updateCard(stripeToken: string): Observable<any> {
    const fd = new FormData();
    fd.append('stripeToken', stripeToken.trim());

    return this.http.post(
      environment.API_URL + `/subscription/update-card`,
      fd
    );
  }

  glassfyPurchase(model: any): Observable<ServerResponse<UserSubscription[]>> {
    return this.http
      .post<ServerResponse<UserSubscription[]>>(
        environment.API_URL + '/subscription/purchased',
        model
      )
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          } else {
          }
        })
      );
  }

  glassfyRestore(): Observable<ServerResponse<UserSubscription[]>> {
    return this.http
      .post<ServerResponse<UserSubscription[]>>(
        environment.API_URL +
          '/subscription/' +
          AppService.glassfy_subscriberId +
          '/restore',
        {}
      )
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          } else {
          }
        })
      );
  }

  activate(productId: string): Observable<ServerResponse<UserSubscription[]>> {
    return this.http
      .post<ServerResponse<UserSubscription[]>>(
        environment.API_URL +
          '/subscription/' +
          AppService.glassfy_subscriberId +
          '/activate/' +
          productId,
        {}
      )
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          } else {
          }
        })
      );
  }

  isExpired(date: Date) {
    const t = new Date(date);
    const now = new Date();

    return now.getTime() > t.getTime();
  }

  stripeSubscribe(
    planId: string,
    coupon: string
  ): Observable<ServerResponse<UserSubscription>> {
    const fd = new FormData();
    fd.append('coupon', coupon);
    return this.http.post<ServerResponse<UserSubscription>>(
      environment.API_URL + `/subscription/stripe-subscribe/${planId}`,
      fd
    );
  }

  cancelStripeSubscription(planId: string): Observable<any> {
    return this.http.post(
      environment.API_URL + `/subscription/stripe-cancel-plan/${planId}`,
      {}
    );
  }

  reactiveStripeSubscription(planId: string): Observable<any> {
    return this.http.post(
      environment.API_URL + `/subscription/stripe-reactivate-plan/${planId}`,
      {}
    );
  }

  validateToken(coupon: string): Observable<any> {
    return this.http.get(
      environment.API_URL + `/subscription/validate-coupon?coupon=${coupon}`,
      {}
    );
  }
}
